<template>
  <div class='col-12 col-lg-6 g c'>
    <br><br><br><br><br><br>
    <div class="card card-body" style="border-radius: 50px">
        <h1 class="text-center">
            <img :src="require('@/assets/videos/success.gif')">
            <br>
            تم تثبيت الاداة بنجاح!
            <br>
        </h1>
        <span class="text-muted text-center">قم الآن فتح منصة مدرستي وستجد الاداة موجودة بها <br>مع الحصول على فترة 7 ايام مجاناً للتجربة.</span>
        <div class="col-6 text-center g c">
            <br>
            <a href="https://app.naqar.net/m/subscribe" target="_blank" class="btn btn-success btn-block btn-lg">
                <i class="fa fa-check-square"></i>
                للإشتراك الآن إضغط هنا
            </a>
            <br>
            <a href="https://brmja.com/howto#mnaqar" target="_blank" class="btn btn-danger btn-block btn-lg">
                <i class="fa fa-play"></i>
                لشروحات الاداة اضغط هنا
            </a>
            <br>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=966580200670" class="btn btn-primary btn-block btn-lg">
                <i class="fa fa-whatsapp"></i>
                للتواصل مع الدعم الفني
            </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>